import { camelizeKeys, decamelizeKeys } from 'humps'
import moment from 'moment'
import { getToken } from './storage'
import { createQueryString } from '../utils/queryParams'
import { getCookie } from '../services/cookies'
import { getTimeZone } from '../utils/date'


const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
}

const prepareRequestBody = body =>
  JSON.stringify(decamelizeKeys(body, { split: /(?=[A-Z]|2fa)/ }))

async function createRequest(resource, opts = {}, auth = true, form = false) {
  const url = `${process.env.REACT_APP_API_BASE}${resource}`
  let authHeader = {}

  if (!form && opts.body) {
    opts.body = prepareRequestBody(opts.body)
  }

  if (auth) {
    const token = getToken()

    if (token) {
      authHeader = { Authorization: token }
    } else {
      // mock the shape of an API error here to display the proper error
      // message to user
      return Promise.reject({ apiError: { message: 'unauthorized' } })
    }
  }

  const mergedOpts = {
    ...opts,
    headers: {
      Accept: 'application/json',
      ...authHeader,
    },
  }

  if (!form) {
    mergedOpts.headers['Content-Type'] = 'application/json'
  }

  if (!csrfSafeMethod(opts.method)) {
    const csrfToken = getCookie('_csrf_token')

    mergedOpts.headers['X-CSRFToken'] = csrfToken
  }

  // Fixes Delete APIs issue on enabling CSRF
  if (opts.method === HTTP_METHODS.DELETE) {
    mergedOpts.body = '{}'
  }

  try {
    const response = await fetch(url, mergedOpts)

    if (!response.ok) {
      let apiError = {}

      if (response.status === 401) {
        const text = await response.text()

        if (text) {
          try {
            apiError = JSON.parse(text)
          } catch (error) {}
        } else {
          apiError = { message: 'unauthorized' }
        }
      } else {
        const text = await response.text()

        try {
          apiError = JSON.parse(text)
        } catch (error) {}
      }

      return Promise.reject(Object.assign(response, { apiError }))
    }

    if (response.status === 204) {
      return
    }

    const json = await response.json()

    return camelizeKeys(
      json,
      // we don't want to convert keys that are ALL CAPS
      (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

function createUnauthorizedRequest(resource, opts) {
  return createRequest(resource, opts, false)
}

const authorize = credentials => {
  const resource = '/auth/login'
  const opts = {
    method: HTTP_METHODS.POST,
    body: credentials,
  }

  return createUnauthorizedRequest(resource, opts)
}

const requestResetPasswordEmail = email => {
  const resource = '/reset-password'
  const opts = {
    method: HTTP_METHODS.POST,
    body: { email },
  }

  return createUnauthorizedRequest(resource, opts)
}

const verifyResetPasswordToken = token => {
  const resource = `/reset-password/${token}`

  return createUnauthorizedRequest(resource)
}

const resetPassword = (token, password) => {
  const resource = `/reset-password/${token}`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { password },
  }

  return createUnauthorizedRequest(resource, opts)
}

const requestAccountConfirmationEmail = email => {
  const resource = '/resend-confirmation'
  const opts = {
    method: HTTP_METHODS.POST,
    body: { email },
  }

  return createUnauthorizedRequest(resource, opts)
}

const getUnconfirmedUser = token => {
  const resource = `/confirm/${token}`

  return createUnauthorizedRequest(resource)
}

const confirmAccount = (token, user) => {
  const resource = `/confirm/${token}`
  const opts = {
    method: HTTP_METHODS.POST,
    body: user,
  }

  return createUnauthorizedRequest(resource, opts)
}

const resendTwoFactorAuthToken = body => {
  const resource = `/token/resend`
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }
  return createUnauthorizedRequest(resource, opts)
}

const verifyTwoFactorAuthToken = body => {
  const resource = `/token/verify`
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }
  return createUnauthorizedRequest(resource, opts)
}

// companies

const uploadLogo = (companyId, formData) => {
  const resource = `/company/${companyId}/upload`
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const getAllCompanies = (qs = '') => {
  const resource = `/companies${qs}`

  return createRequest(resource)
}

const getCompanyById = id => {
  const resource = `/company/${id}`

  return createRequest(resource)
}

const createCompany = company => {
  const resource = '/companies'
  const opts = {
    method: HTTP_METHODS.POST,
    body: company,
  }

  return createRequest(resource, opts)
}

const updateCompany = company => {
  const resource = `/company/${company.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: company,
  }

  return createRequest(resource, opts)
}

const deleteCompany = id => {
  const resource = `/company/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllUsersByCompany = (id, qs = '') => {
  const resource = `/company/${id}/users${qs}`

  return createRequest(resource)
}

const getAllSitesByCompany = (id, qs = '') => {
  const resource = `/company/${id}/sites${qs}`

  return createRequest(resource)
}

const getSiteAssets = siteSlug => {
  const resource = `/site/${siteSlug}/inventory`

  return createRequest(resource)
}

// TODO deprecated
const getAllEscalationPoliciesByCompany = (id, qs = '') => {
  const resource = `/company/${id}/escalation-policies${qs}`

  return createRequest(resource)
}

// users

const getAllUsers = (qs = '') => {
  const resource = `/users${qs}`

  return createRequest(resource)
}

const getUserBySlug = slug => {
  const resource = `/user/${slug}`

  return createRequest(resource)
}

const createUser = user => {
  const resource = '/users'
  const opts = {
    method: HTTP_METHODS.POST,
    body: user,
  }

  return createRequest(resource, opts)
}

const updateUser = user => {
  const resource = `/user/${user.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: user,
  }

  return createRequest(resource, opts)
}

const deleteUser = slug => {
  const resource = `/user/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const updateUserDefaultSite = ({ userSlug, siteSlug }) => {
  const resource = `/user/${userSlug}/default-site`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: { siteSlug },
  }

  return createRequest(resource, opts)
}

const getUserCommunicationLog = (slug, qs = '') => {
  const resource = `/user/${slug}/communication-log${qs}`

  return createRequest(resource)
}

const getUserPermissionsBySlug = slug => {
  const resource = `/user/${slug}/permissions`

  return createRequest(resource)
}

const updateUserPermissions = (slug, permissions) => {
  const resource = `/user/${slug}/permissions`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: { permissions },
  }

  return createRequest(resource, opts)
}

// sites

const getSiteActivityLog = ({ siteSlug, qs = '' }) => {
  const resource = `/site/${siteSlug}/activity-log${qs}`

  return createRequest(resource)
}

const getSiteContractors = ({ siteSlug, qs = '' }) => {
  const resource = `/site/${siteSlug}/contractors${qs}`

  return createRequest(resource)
}

const getAllImuNanoIots = () => {
  const resource = `/imus/data`

  return createRequest(resource)
}

const getImuData = ({ macAddress, qs = '' }) => {
  const resource = `/imu/${macAddress}/data${qs}`

  return createRequest(resource)
}

const getAllFlowMonitorsData = () => {
  const resource = `/flow-monitors/data`

  return createRequest(resource)
}

const getFlowMonitorData = ({ pillarId, qs = '' }) => {
  const resource = `/flow-monitor/${pillarId}/data${qs}`

  return createRequest(resource)
}

const addSiteContractor = (siteSlug, contractorCompanySlug) => {
  const resource = `/site/${siteSlug}/contractors`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { contractorCompanySlug },
  }

  return createRequest(resource, opts)
}

const deleteSiteContractor = (siteSlug, contractorId) => {
  const resource = `/site/${siteSlug}/contractor/${contractorId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getSiteRiMetrics = ({ siteSlug, qs = '' }) => {
  const resource = `/site/${siteSlug}/reliability-metrics${qs}`

  return createRequest(resource)
}

const getAllSites = (qs = '') => {
  const resource = `/sites${qs}`

  return createRequest(resource)
}

const getSiteBySlug = siteSlug => {
  const resource = `/site/${siteSlug}`

  return createRequest(resource)
}

const createSite = site => {
  const resource = '/sites'
  const opts = {
    method: HTTP_METHODS.POST,
    body: site,
  }

  return createRequest(resource, opts)
}

const updateSite = site => {
  const resource = `/site/${site.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: site,
  }
  console.log("updating site")
  console.log("site: ", site)

  return createRequest(resource, opts)
}

const deleteSite = siteSlug => {
  const resource = `/site/${siteSlug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllUsersBySite = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/users${qs}`

  return createRequest(resource)
}

const addUserToSite = (siteSlug, userId) => {
  const resource = `/site/${siteSlug}/users`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { userId },
  }

  return createRequest(resource, opts)
}

const deleteUserFromSite = (siteSlug, userId) => {
  const resource = `/site/${siteSlug}/user/${userId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getAllGatewaysBySite = siteSlug => {
  const resource = `/site/${siteSlug}/gateways`

  return createRequest(resource)
}

const addGatewayToSite = (siteSlug, gatewayId) => {
  const resource = `/site/${siteSlug}/gateways`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { gatewayId },
  }

  return createRequest(resource, opts)
}

const deleteGatewayFromSite = (siteSlug, gatewayId) => {
  const resource = `/site/${siteSlug}/gateway/${gatewayId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getAllThresholdsBySite = siteSlug => {
  const resource = `/site/${siteSlug}/thresholds`

  return createRequest(resource)
}

const getAllAggregateThresholdsBySite = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/thresholds/all${qs}`

  return createRequest(resource)
}

const addThresholdToSite = (siteSlug, thresholdId, thresholdGroupSlug) => {
  const resource = `/site/${siteSlug}/thresholds`
  const opts = {
    method: HTTP_METHODS.POST,
    body: {
      thresholdId,
      thresholdGroupSlug,
    },
  }

  return createRequest(resource, opts)
}

const addEscalationPolicytoSiteThreshold = (
  siteSlug,
  thresholdId,
  escalationPolicySlug
) => {
  const resource = `/site/${siteSlug}/threshold/${thresholdId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: { escalationPolicySlug },
  }

  return createRequest(resource, opts)
}

const deleteThresholdFromSite = (siteSlug, thresholdId) => {
  const resource = `/site/${siteSlug}/threshold/${thresholdId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const sendTestNotification = id => {
  const resource = `/threshold/${id}/test-notification`
  const opts = {
    method: HTTP_METHODS.PUT,
  }

  return createRequest(resource, opts)
}

const generateGrafanaJSON = siteSlug => {
  const resource = `/site/${siteSlug}/generate-grafana-json`

  return createRequest(resource)
}

const getAllSiteThresholdUserMappings = siteSlug => {
  const resource = `/site/${siteSlug}/threshold-user-mappings`

  return createRequest(resource)
}

const getAllSiteAggregateThresholdUserMappings = siteSlug => {
  const resource = `/site/${siteSlug}/threshold-user-mappings/all`

  return createRequest(resource)
}

const updateSiteThresholdUserMapping = mapping => {
  const resource = `/site/${mapping.siteSlug}/threshold-user-mapping/${mapping.mappingId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: mapping,
  }

  return createRequest(resource, opts)
}

const createSiteActionReport = (siteSlug, report) => {
  const resource = `/site/${siteSlug}/action-reports`
  const opts = {
    method: HTTP_METHODS.POST,
    body: report,
  }

  return createRequest(resource, opts)
}

const updateSiteActionReport = (siteSlug, actionReportSlug, report) => {
  const resource = `/site/${siteSlug}/action-report/${actionReportSlug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: report,
  }

  return createRequest(resource, opts)
}

const deleteSiteActionReport = (siteSlug, reportSlug) => {
  const resource = `/site/${siteSlug}/action-report/${reportSlug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const uploadUsersCSV = (siteSlug, formData) => {
  const resource = `/site/${siteSlug}/users/upload`
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const getAllAvailableUsersBySite = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/users/available${qs}`

  return createRequest(resource)
}

const getInventoryHistoryBySite = siteSlug => {
  const resource = `/site/${siteSlug}/inventory/tracker`

  return createRequest(resource)
}

const getAllSiteInvoices = siteSlug => {
  const resource = `/site/${siteSlug}/invoices`

  return createRequest(resource)
}

const createSiteInvoice = (siteSlug, invoice) => {
  const resource = `/site/${siteSlug}/invoices`
  const opts = {
    method: HTTP_METHODS.POST,
    body: invoice,
  }

  return createRequest(resource, opts)
}

const updateSiteInvoice = (siteSlug, invoice) => {
  const resource = `/site/${siteSlug}/invoice/${invoice.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: invoice,
  }

  return createRequest(resource, opts)
}

const deleteSiteInvoice = (siteSlug, slug) => {
  const resource = `/site/${siteSlug}/invoice/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// site documents
const uploadSiteDocument = (siteSlug, formData) => {
  const resource = `/site/${siteSlug}/document-upload`
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const getAllSiteDocuments = siteSlug => {
  const resource = `/site/${siteSlug}/documents`

  return createRequest(resource)
}

// buildings

const getAllBuildings = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/buildings${qs}`

  return createRequest(resource)
}

const getBuildingById = (siteSlug, id) => {
  const resource = `/site/${siteSlug}/building/${id}`

  return createRequest(resource)
}

const createBuilding = (siteSlug, building) => {
  const resource = `/site/${siteSlug}/buildings`
  const opts = {
    method: HTTP_METHODS.POST,
    body: building,
  }

  return createRequest(resource, opts)
}

const updateBuilding = (siteSlug, building) => {
  const resource = `/site/${siteSlug}/building/${building.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: building,
  }

  return createRequest(resource, opts)
}

const deleteBuilding = (siteSlug, id) => {
  const resource = `/site/${siteSlug}/building/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllAvailablePodsBySite = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/pods/available${qs}`

  return createRequest(resource)
}

const getAllThresholdsByBuilding = (siteSlug, buildingId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/thresholds`

  return createRequest(resource)
}

const addThresholdToBuilding = (siteSlug, buildingId, thresholdId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/thresholds`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { thresholdId },
  }

  return createRequest(resource, opts)
}

const deleteThresholdFromBuilding = (siteSlug, buildingId, thresholdId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/threshold/${thresholdId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getAllBuildingThresholdUserMappings = (siteSlug, buildingId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/threshold-user-mappings`

  return createRequest(resource)
}

const updateBuildingThresholdUserMapping = mapping => {
  const resource = `/site/${mapping.siteSlug}/building/${mapping.buildingId}/threshold-user-mapping/${mapping.mappingId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: mapping,
  }

  return createRequest(resource, opts)
}

// gateways

const getAllGateways = (qs = '') => {
  const resource = `/gateways${qs}`

  return createRequest(resource)
}

const createGateway = gateway => {
  const resource = '/gateways'
  const opts = {
    method: HTTP_METHODS.POST,
    body: gateway,
  }

  return createRequest(resource, opts)
}

const getAllAvailableGateways = siteSlug => {
  const resource = `/site/${siteSlug}/gateways/available`

  return createRequest(resource)
}

const getGatewayById = id => {
  const resource = `/gateway/${id}`

  return createRequest(resource)
}

const updateGateway = gateway => {
  const resource = `/gateway/${gateway.gatewayPillarId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: gateway,
  }

  return createRequest(resource, opts)
}

const restartGatewayById = id => {
  const resource = `/gateway/${id}/restart`

  const opts = {
    method: HTTP_METHODS.POST,
    body: {},
  }

  return createRequest(resource, opts)
}

const deleteGateway = id => {
  const resource = `/gateway/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getGatewayRestartHistoryByPillarId = pillarId => {
  const resource = `/gateway/${pillarId}/restart-log`

  return createRequest(resource)
}

// SIM cards

const getAllSIMCards = (qs = '') => {
  const resource = `/sim-cards${qs}`

  return createRequest(resource)
}

const createSIMCard = simCard => {
  const resource = '/sim-cards'
  const opts = {
    method: HTTP_METHODS.POST,
    body: simCard,
  }

  return createRequest(resource, opts)
}

const getAllAvailableSIMCards = () => {
  const resource = '/sim-cards/available'

  return createRequest(resource)
}

const getSIMCard = id => {
  const resource = `/sim-card/${id}`

  return createRequest(resource)
}

const updateSIMCard = simCard => {
  const resource = `/sim-card/${simCard.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: simCard,
  }

  return createRequest(resource, opts)
}

const deleteSIMCard = id => {
  const resource = `/sim-card/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// Parts

const getAllParts = (qs = '') => {
  const resource = `/parts${qs}`

  return createRequest(resource)
}

const createPart = part => {
  const resource = '/parts'
  const opts = {
    method: HTTP_METHODS.POST,
    body: part,
  }

  return createRequest(resource, opts)
}

const getAllAvailableParts = () => {
  const resource = '/parts/available'

  return createRequest(resource)
}

const getPart = id => {
  const resource = `/part/${id}`

  return createRequest(resource)
}

const updatePart = (part, id) => {
  const resource = `/part/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: part,
  }

  return createRequest(resource, opts)
}

const deletePart = id => {
  const resource = `/part/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// Purchases

const getAllPurchases = (qs = '') => {
  const resource = `/purchases${qs}`

  return createRequest(resource)
}

const createPurchase = purchase => {
  const resource = '/purchases'
  const opts = {
    method: HTTP_METHODS.POST,
    body: purchase,
  }

  return createRequest(resource, opts)
}

const getAllAvailablePurchases = () => {
  const resource = '/purchases/available'

  return createRequest(resource)
}

const getPurchase = id => {
  const resource = `/purchase/${id}`

  return createRequest(resource)
}

const updatePurchase = (purchase, id) => {
  const resource = `/purchase/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: purchase,
  }

  return createRequest(resource, opts)
}

const deletePurchase = id => {
  const resource = `/purchase/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// Water Monitors

const getAllFlowMonitors = (qs = '') => {
  const resource = `/flow-monitors${qs}`

  return createRequest(resource)
}

const getFlowMonitor = id => {
  const resource = `/flow-monitor/${id}`

  return createRequest(resource)
}

const createFlowMonitor = flow => {
  const resource = `/flow-monitors`
  const opts = {
    method: HTTP_METHODS.POST,
    body: flow,
  }

  return createRequest(resource, opts)
}

const updateFlowMonitor = (flow, id) => {
  const resource = `/flow-monitor/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: flow,
  }

  return createRequest(resource, opts)
}

const deleteFlowMonitor = id => {
  const resource = `/flow-monitor/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllWaterMeters = (qs = '') => {
  const resource = `/water-meters${qs}`

  return createRequest(resource)
}

const getWaterMeter = id => {
  const resource = `/water-meter/${id}`

  return createRequest(resource)
}

const createWaterMeter = flow => {
  const resource = `/water-meters`
  const opts = {
    method: HTTP_METHODS.POST,
    body: flow,
  }

  return createRequest(resource, opts)
}

const updateWaterMeter = (flow, id) => {
  const resource = `/water-meter/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: flow,
  }

  return createRequest(resource, opts)
}

const deleteWaterMeter = id => {
  const resource = `/water-meter/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllWaterValves = (qs = '') => {
  const resource = `/water-valves${qs}`

  return createRequest(resource)
}

const getWaterValve = id => {
  const resource = `/water-valve/${id}`

  return createRequest(resource)
}

const createWaterValve = flow => {
  const resource = `/water-valves`
  const opts = {
    method: HTTP_METHODS.POST,
    body: flow,
  }

  return createRequest(resource, opts)
}

const updateWaterValve = (flow, id) => {
  const resource = `/water-valve/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: flow,
  }

  return createRequest(resource, opts)
}

const deleteWaterValve = id => {
  const resource = `/water-valve/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllAvailableFlowMonitorsBySite = siteSlug => {
  const resource = `/site/${siteSlug}/flow-monitors/available`

  return createRequest(resource)
}

const getAllAvailableWaterValves = (qs = '') => {
  const resource = `/water-valves/available${qs}`

  return createRequest(resource)
}

const getAllAvailableWaterMeters = (qs = '') => {
  const resource = `/water-meters/available${qs}`

  return createRequest(resource)
}
// Gateway Supplies

const getAllGatewaySupplies = () => {
  const resource = `/inventory/gateway-supplies`

  return createRequest(resource)
}

const createGatewaySupply = gatewaySupply => {
  const resource = '/inventory/gateway-supplies'
  const opts = {
    method: HTTP_METHODS.POST,
    body: gatewaySupply,
  }

  return createRequest(resource, opts)
}

const getAllAvailableGatewaySupplies = () => {
  const resource = '/inventory/gateway-supplies/available'

  return createRequest(resource)
}

const getGatewaySupply = id => {
  const resource = `/inventory/gateway-supply/${id}`

  return createRequest(resource)
}

const updateGatewaySupply = (gatewaySupply, id) => {
  const resource = `/inventory/gateway-supply/${id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: gatewaySupply,
  }

  return createRequest(resource, opts)
}

const deleteGatewaySupply = id => {
  const resource = `/inventory/gateway-supply/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// asset Locations
const createAssetLocation = payload => {
  const resource = `/asset-locations`

  const opts = {
    method: HTTP_METHODS.POST,
    body: payload,
  }

  return createRequest(resource, opts)
}

const getAllAssetLocations = (qs = '') => {
  const resource = `/asset-locations${qs}`

  return createRequest(resource)
}

const getAssetLocation = id => {
  const resource = `/asset-location/${id}`

  return createRequest(resource)
}

const updateAssetLocation = assetLocation => {
  const resource = `/asset-location/${assetLocation.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: assetLocation,
  }

  return createRequest(resource, opts)
}

const deleteAssetLocation = id => {
  const resource = `/asset-location/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// pods

const uploadCalibrationCSV = formData => {
  const resource = '/pods/upload/calibration'
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const uploadCalibrationZipfile = formData => {
  const resource = '/pods/upload/calibration-zip'
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const uploadPodsCSV = formData => {
  const resource = '/pods/upload'
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const generatePillarIDs = payload => {
  const resource = `/pods/generate-ids`

  const opts = {
    method: HTTP_METHODS.POST,
    body: payload,
  }

  return createRequest(resource, opts)
}

const getPodMetadata = () => {
  const resource = `/pods/metadata`

  return createRequest(resource)
}

const getAllPodFirmwareVersions = () => {
  const resource = '/pods/get-firmware-versions'

  return createRequest(resource)
}

const updatePodFirmware = body => {
  const resource = '/pods/update-firmware'
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }

  return createRequest(resource, opts)
}

const getAllPods = (qs = '') => {
  const resource = `/pods${qs}`

  return createRequest(resource)
}

// TODO deprecated
const getAllAvailablePods = () => {
  const resource = '/pods/available'

  return createRequest(resource)
}

const createPod = pod => {
  const resource = '/pods'
  const opts = {
    method: HTTP_METHODS.POST,
    body: pod,
  }

  return createRequest(resource, opts)
}

const getPodById = id => {
  const resource = `/pod/${id}`

  return createRequest(resource)
}

const getPodChartDataById = (id, qs = '') => {
  const resource = `/pod/${id}/data${qs}`

  return createRequest(resource)
}

const updatePod = pod => {
  const resource = `/pod/${pod.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: pod,
  }

  return createRequest(resource, opts)
}

const markPodAsLost = pod => {
  const resource = `/pod/${pod.id}/mark-as-lost`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: pod,
  }

  return createRequest(resource, opts)
}

const deletePod = id => {
  const resource = `/pod/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getPodAssignmentHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/pod/${id}/assignment-history${qs}`

  return createRequest(resource)
}

const getPodAssignmentHistoryByLocation = (siteSlug, floorId, locationId) => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/history${qs}`

  return createRequest(resource)
}

const getPodAssignmentHistoryBySite = siteSlug => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/site/${siteSlug}/pods/history${qs}`

  return createRequest(resource)
}

const getGatewayAssignmentHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/gateway/${id}/assignment-history${qs}`

  return createRequest(resource)
}

const getPodEditHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/pod/${id}/edit/history${qs}`

  return createRequest(resource)
}

const getFlowMonitorAssignmentHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/flow-monitor/${id}/assignment-history${qs}`

  return createRequest(resource)
}

const getValveStatusHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/water-valve/${id}/status-history${qs}`

  return createRequest(resource)
}

const getFlowMonitorEditHistory = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/flow-monitor/${id}/edit/history${qs}`

  return createRequest(resource)
}

const getPodCalibrationHistory = (pillarId, qs = '') => {
  const resource = `/pod/${pillarId}/calibration/history${qs}`

  return createRequest(resource)
}

const getThresholdEditHistoryById = id => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/threshold/${id}/edit/history${qs}`

  return createRequest(resource)
}

const batchUpdatePods = body => {
  const resource = '/pods/batch'
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const getPodAssets = (pillarId, qs = '') => {
  const resource = `/pod/${pillarId}/assets/list${qs}`

  return createRequest(resource)
}

const getMultiplePodsData = (qs = '') => {
  const resource = `/pods/data${qs}`

  return createRequest(resource)
}

// floors

const getAllFloors = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/floors${qs}`

  return createRequest(resource)
}

const getFloorByFloor = (siteSlug, floorId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}`

  return createRequest(resource)
}

const getAllFloorSummaries = siteSlug => {
  const resource = `/site/${siteSlug}/floors/summary`

  return createRequest(resource)
}

const getFloorSummary = (siteSlug, floorId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/summary`

  return createRequest(resource)
}

const createFloor = (siteSlug, floor) => {
  const resource = `/site/${siteSlug}/floors`
  const opts = {
    method: HTTP_METHODS.POST,
    body: floor,
  }

  return createRequest(resource, opts)
}

const submitSiteFloors = (siteSlug, floor) => {
  const resource = `/site/${siteSlug}/floors/submit`
  const opts = {
    method: HTTP_METHODS.POST,
    body: floor,
  }

  return createRequest(resource, opts)
}

const updateFloor = (siteSlug, floorId, floor) => {
  const resource = `/site/${siteSlug}/floor/${floorId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: floor,
  }

  return createRequest(resource, opts)
}

const uploadFloorPlan = (siteSlug, floorId, formData) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/upload`
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const uploadFloorPlanPdf = (siteSlug, formData) => {
  const resource = `/site/${siteSlug}/floorplan`
  const opts = {
    method: HTTP_METHODS.POST,
    body: formData,
  }

  return createRequest(resource, opts, true, true)
}

const getFloorPlanSnaps = siteSlug => {
  const resource = `/site/${siteSlug}/floorplan/edit`

  return createRequest(resource)
}

const deleteFloor = (siteSlug, floorId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllThresholdsByFloor = (siteSlug, floorSlug) => {
  const resource = `/site/${siteSlug}/floor/${floorSlug}/thresholds`

  return createRequest(resource)
}

const addThresholdToFloor = (siteSlug, floorSlug, thresholdId) => {
  const resource = `/site/${siteSlug}/floor/${floorSlug}/thresholds`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { thresholdId },
  }

  return createRequest(resource, opts)
}

const deleteThresholdFromFloor = (siteSlug, floorSlug, thresholdId) => {
  const resource = `/site/${siteSlug}/floor/${floorSlug}/threshold/${thresholdId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getAllFloorThresholdUserMappings = (siteSlug, floorSlug) => {
  const resource = `/site/${siteSlug}/floor/${floorSlug}/threshold-user-mappings`

  return createRequest(resource)
}

const updateFloorThresholdUserMapping = mapping => {
  const resource = `/site/${mapping.siteSlug}/floor/${mapping.floorId}/threshold-user-mapping/${mapping.mappingId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: mapping,
  }

  return createRequest(resource, opts)
}

const getAllFloorsByBuildingId = (siteSlug, buildingId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/floors`

  return createRequest(resource)
}

const getAllFloorSummariesByBuildingId = (siteSlug, buildingId) => {
  const resource = `/site/${siteSlug}/building/${buildingId}/floors/summary`

  return createRequest(resource)
}

const updateFloorSortOrderBySiteSlug = (siteSlug, sortOrder) => {
  const resource = `/site/${siteSlug}/floors/sort`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: sortOrder,
  }

  return createRequest(resource, opts)
}

// locations

const getAllLocationsBySite = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/locations${qs}`

  return createRequest(resource)
}

const getAllLocationsByFloor = (siteSlug, floorId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/locations`

  return createRequest(resource)
}

const getLocationAlertHistory = (siteSlug, floorId, locationId, qs = '') => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/alert-history${qs}`

  return createRequest(resource)
}

const getLocationUptimeData = (siteSlug, floorId, locationId, qs = '') => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/alerts${qs}`

  return createRequest(resource)
}

// TODO this should be called `/last` like the others but it returns most recent
// readings for each location on floor
const getAllLocationsSnapshotDataByFloor = (siteSlug, floorId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/locations/data`

  return createRequest(resource)
}

const getLocationById = (siteSlug, floorId, id) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${id}`

  return createRequest(resource)
}

const getLocationReadingsById = (siteSlug, floorId, id) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${id}/last`

  return createRequest(resource)
}

const getLocationChartDataById = (siteSlug, floorId, id, qs = '') => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${id}/data${qs}`

  return createRequest(resource)
}

const createLocation = (siteSlug, floorId, location) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/locations`
  const opts = {
    method: HTTP_METHODS.POST,
    body: location,
  }

  return createRequest(resource, opts)
}

const updateGatewayLocation = (siteSlug, gateway) => {
  const resource = `/site/${siteSlug}/gateway/${gateway.gatewayId}`

  const opts = {
    method: HTTP_METHODS.PUT,
    body: gateway,
  }

  return createRequest(resource, opts)
}

const updateLocation = (siteSlug, floorId, location) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${location.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: location,
  }

  return createRequest(resource, opts)
}

const deleteLocation = (siteSlug, floorId, id) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllThresholdsByLocation = (siteSlug, floorId, locationId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/thresholds`

  return createRequest(resource)
}

const addThresholdToLocation = (siteSlug, floorId, locationId, thresholdId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/thresholds`
  const opts = {
    method: HTTP_METHODS.POST,
    body: { thresholdId },
  }

  return createRequest(resource, opts)
}

const deleteThresholdFromLocation = (
  siteSlug,
  floorId,
  locationId,
  thresholdId
) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/threshold/${thresholdId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const addEscalationPolicytoLocationThreshold = (
  siteSlug,
  floorId,
  locationId,
  thresholdId,
  escalationPolicySlug
) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/threshold/${thresholdId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: { escalationPolicySlug },
  }

  return createRequest(resource, opts)
}

const getLocationDataByLocationIds = (siteSlug, qs) => {
  const resource = `/site/${siteSlug}/locations/data${qs}`

  return createRequest(resource)
}

const getAllLocationThresholdUserMappings = (siteSlug, floorId, locationId) => {
  const resource = `/site/${siteSlug}/floor/${floorId}/location/${locationId}/threshold-user-mappings`

  return createRequest(resource)
}

const updateLocationThresholdUserMapping = mapping => {
  const resource = `/site/${mapping.siteSlug}/floor/${mapping.floorId}/location/${mapping.locationId}/threshold-user-mapping/${mapping.mappingId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: mapping,
  }

  return createRequest(resource, opts)
}

// thresholds

const getAllThresholds = (qs = '') => {
  const resource = `/thresholds${qs}`

  return createRequest(resource)
}

const createThreshold = threshold => {
  const resource = '/thresholds'
  const opts = {
    method: HTTP_METHODS.POST,
    body: threshold,
  }

  return createRequest(resource, opts)
}

const getThresholdById = id => {
  const resource = `/threshold/${id}`

  return createRequest(resource)
}

const updateThreshold = threshold => {
  const resource = `/threshold/${threshold.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: threshold,
  }

  return createRequest(resource, opts)
}

const deleteThreshold = id => {
  const resource = `/threshold/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getThresholdAssignmentsById = id => {
  const resource = `/threshold/${id}/assignments`

  return createRequest(resource)
}

// threshold groups

const getAllThresholdGroups = (qs = '') => {
  const resource = `/threshold/groups${qs}`

  return createRequest(resource)
}

const createThresholdGroup = thresholdGroup => {
  const resource = `/threshold/groups`
  const opts = {
    method: HTTP_METHODS.POST,
    body: thresholdGroup,
  }

  return createRequest(resource, opts)
}

const updateThresholdGroup = thresholdGroup => {
  const resource = `/threshold/group/${thresholdGroup.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: thresholdGroup,
  }

  return createRequest(resource, opts)
}

const deleteThresholdGroup = slug => {
  const resource = `/threshold/group/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getThresholdGroupBySlug = slug => {
  const resource = `/threshold/group/${slug}`

  return createRequest(resource)
}

// tags
const getAllTags = (qs = '') => {
  const resource = `/tags${qs}`

  return createRequest(resource)
}

const createTag = tag => {
  const resource = `/tags`
  const opts = {
    method: HTTP_METHODS.POST,
    body: tag,
  }

  return createRequest(resource, opts)
}

const updateTag = tag => {
  const resource = `/tag/${tag.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: tag,
  }

  return createRequest(resource, opts)
}

const deleteTag = id => {
  const resource = `/tag/${id}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getTagById = id => {
  const resource = `/tag/${id}`

  return createRequest(resource)
}

// comments

const getAllComments = (qs = '') => {
  const resource = `/comments${qs}`

  return createRequest(resource)
}

const createComment = comment => {
  const resource = `/comments`
  const opts = {
    method: HTTP_METHODS.POST,
    body: comment,
  }

  return createRequest(resource, opts)
}

const updateComment = comment => {
  const resource = `/comment/${comment.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: comment,
  }

  return createRequest(resource, opts)
}

const deleteComment = commentSlug => {
  const resource = `/comment/${commentSlug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllAvailbleUserMentions = qs => {
  const resource = `/comments/users/available${qs}`

  return createRequest(resource)
}

// alerts

const getAllAlerts = (qs = '') => {
  const resource = `/alerts${qs}`

  return createRequest(resource)
}

const getAllGatewayAlerts = (qs = '') => {
  const resource = `/gateway-alerts${qs}`

  return createRequest(resource)
}

const getAlertBySlug = slug => {
  const resource = `/alert/${slug}`

  return createRequest(resource)
}

const getGatewayAlertBySlug = slug => {
  const resource = `/gateway-alert/${slug}`

  return createRequest(resource)
}

const getGatewayUptimeData = (gatewayId, qs = '') => {
  const resource = `/gateway/${gatewayId}/alerts${qs}`

  return createRequest(resource)
}

const getGatewayConnectedPods = (gatewayId, qs = '') => {
  const resource = `/gateway/${gatewayId}/connected-pods`

  return createRequest(resource)
}

const getAlertAuditLog = id => {
  const resource = `/alert/${id}/audit-log`

  return createRequest(resource)
}

const hideAlert = id => {
  const resource = `/alert/${id}/hide`
  const opts = {
    method: HTTP_METHODS.PUT,
  }

  return createRequest(resource, opts)
}

const unhideAlert = id => {
  const resource = `/alert/${id}/unhide`
  const opts = {
    method: HTTP_METHODS.PUT,
  }

  return createRequest(resource, opts)
}

const closeAlert = id => {
  const resource = `/alert/${id}/close`
  const opts = {
    method: HTTP_METHODS.PUT,
  }

  return createRequest(resource, opts)
}

const closeGatewayAlert = slug => {
  const resource = `/gateway-alert/${slug}/close`
  const opts = {
    method: HTTP_METHODS.PUT,
  }

  return createRequest(resource, opts)
}

const acknowledgeAlert = slug => {
  const resource = `/alert/${slug}/ack`
  const opts = {
    method: HTTP_METHODS.POST,
  }

  return createRequest(resource, opts)
}

const resolveAlert = slug => {
  const resource = `/alert/${slug}/resolve`
  const opts = {
    method: HTTP_METHODS.POST,
  }

  return createRequest(resource, opts)
}

const generateAlertExport = (qs = '') => {
  const resource = `/alerts/export${qs}`
  const opts = { method: HTTP_METHODS.POST }

  return createRequest(resource, opts)
}

const batchUpdateAlerts = body => {
  const resource = '/alerts/batch'
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

// deployments

const getAllDeployments = (qs = '') => {
  const resource = `/deployments${qs}`

  return createRequest(resource)
}

const getDeploymentCounts = () => {
  const resource = `/deployments/dashboard`

  return createRequest(resource)
}

const getAllDeploymentsBySite = siteSlug => {
  const resource = `/site/${siteSlug}/deployments`

  return createRequest(resource)
}

const getDeployment = (siteSlug, deploymentSlug) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}`

  return createRequest(resource)
}

const createDeployment = (siteSlug, deployment) => {
  const resource = `/site/${siteSlug}/deployments`
  const opts = {
    method: HTTP_METHODS.POST,
    body: deployment,
  }

  return createRequest(resource, opts)
}

const updateDeployment = (siteSlug, deploymentSlug, load) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: load,
  }

  return createRequest(resource, opts)
}

const deleteDeployment = (siteSlug, deploymentSlug) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const regeneratePackingList = (siteSlug, deploymentSlug) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}/regenerate-packing-list`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: {},
  }

  return createRequest(resource, opts)
}

const updateDeploymentEstimates = (siteSlug, deploymentSlug, body) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}/edit`
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const getDeploymentEditHistory = (siteSlug, deploymentSlug) => {
  const qs = createQueryString({ perPage: 'all' })
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}/edit/history${qs}`

  return createRequest(resource)
}

// shipments
const getShipment = (siteSlug, deploymentSlug, shipmentId) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}/shipment/${shipmentId}`
  return createRequest(resource)
}

const updateShipment = (siteSlug, deploymentSlug, shipment) => {
  const resource = `/site/${siteSlug}/deployment/${deploymentSlug}/shipment/${shipment.id}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: shipment,
  }

  return createRequest(resource, opts)
}

const getAllShipments = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/shipments${qs}`

  return createRequest(resource)
}

// account profile

const getAccountProfile = () => {
  const resource = '/profile'

  return createRequest(resource)
}

const updateAccountProfile = profile => {
  const resource = '/profile'
  const opts = {
    method: HTTP_METHODS.PUT,
    body: profile,
  }

  return createRequest(resource, opts)
}

const updateAccountPassword = passwords => {
  const resource = '/change-password'
  const opts = {
    method: HTTP_METHODS.POST,
    body: passwords,
  }

  return createRequest(resource, opts)
}

const getDashboardCounts = () => {
  const resource = '/dashboard/counts'

  return createRequest(resource)
}

const getDashboardAlerts = (qs = '') => {
  const resource = `/dashboard/alerts${qs}`

  return createRequest(resource)
}

const getInventoryCounts = () => {
  const resource = '/inventory/data'

  return createRequest(resource)
}

const getInventoryProjections = (qs = '') => {
  const resource = `/inventory/projections${qs}`

  return createRequest(resource)
}

const createInventoryProposal = body => {
  const resource = '/inventory/proposed'
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }

  return createRequest(resource, opts)
}

const getAllInventoryProposals = (qs = '') => {
  const resource = `/inventory/proposed${qs}`

  return createRequest(resource)
}

const getInventoryProposalBySlug = slug => {
  const resource = `/inventory/proposed/${slug}`

  return createRequest(resource)
}

const updateInventoryProposal = body => {
  const resource = `/inventory/proposed/${body.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const deleteInventoryProposal = slug => {
  const resource = `/inventory/proposed/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const generatePillarIDsFromInventoryProposal = slug => {
  const resource = `/inventory/proposed/${slug}/create`
  const opts = { method: HTTP_METHODS.POST }

  return createRequest(resource, opts)
}

// reports

const createReport = body => {
  const resource = '/reports'
  const opts = { method: HTTP_METHODS.POST, body }

  return createRequest(resource, opts)
}

const getAllReports = (qs = '') => {
  const resource = `/reports${qs}`

  return createRequest(resource)
}

const getPublicReportByReportSlug = slug => {
  const resource = `/report/${slug}`

  return createUnauthorizedRequest(resource)
}

const getAllReportConfigs = (qs = '') => {
  const resource = `/reports/configs${qs}`

  return createRequest(resource)
}

const createReportConfig = config => {
  const resource = '/reports/configs'
  const opts = {
    method: HTTP_METHODS.POST,
    body: config,
  }

  return createRequest(resource, opts)
}

const updateReportConfig = config => {
  const resource = `/reports/config/${config.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: config,
  }

  return createRequest(resource, opts)
}

const deleteReportConfig = slug => {
  const resource = `/reports/config/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// verification

const requestVerificationToken = body => {
  const resource = '/token/resend'
  const opts = { method: HTTP_METHODS.POST, body }

  return createRequest(resource, opts)
}

const confirmVerificationToken = body => {
  const resource = '/token/verify'
  const opts = { method: HTTP_METHODS.POST, body }

  return createRequest(resource, opts)
}

// ota

const getAllOTALogs = () => {
  const resource = '/ota/logs'

  return createRequest(resource)
}

const getOTALogBySlug = slug => {
  const resource = `/ota/log/${slug}`

  return createRequest(resource)
}

// escalation policies

const getAllEscalationPolicies = (qs = '') => {
  const resource = `/escalation-policies${qs}`

  return createRequest(resource)
}

const getEscalationPolicyBySlug = slug => {
  const resource = `/escalation-policy/${slug}`

  return createRequest(resource)
}

const createEscalationPolicy = escalationPolicy => {
  const resource = '/escalation-policies'
  const opts = {
    method: HTTP_METHODS.POST,
    body: escalationPolicy,
  }

  return createRequest(resource, opts)
}

const updateEscalationPolicy = escalationPolicy => {
  const resource = `/escalation-policy/${escalationPolicy.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: escalationPolicy,
  }

  return createRequest(resource, opts)
}

const deleteEscalationPolicy = slug => {
  const resource = `/escalation-policy/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// action report causes

const getAllActionReportCauses = (qs = '') => {
  const resource = `/action-report-causes${qs}`

  return createRequest(resource)
}

const getActionReportCauseBySlug = slug => {
  const resource = `/action-report-cause/${slug}`

  return createRequest(resource)
}

const createActionReportCause = cause => {
  const resource = '/action-report-causes'
  const opts = {
    method: HTTP_METHODS.POST,
    body: cause,
  }

  return createRequest(resource, opts)
}

const updateActionReportCause = cause => {
  const resource = `/action-report-cause/${cause.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: cause,
  }

  return createRequest(resource, opts)
}

const deleteActionReportCause = slug => {
  const resource = `/action-report-cause/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// workflows

const getAllWorkflows = () => {
  const resource = '/workflows'

  return createRequest(resource)
}

const createWorkflow = workflow => {
  const resource = `/workflows`
  const opts = {
    method: HTTP_METHODS.POST,
    body: workflow,
  }

  return createRequest(resource, opts)
}

const getWorkflowByType = workflowType => {
  const resource = `/workflow/${workflowType}`

  return createRequest(resource)
}

const getAllWorkflowResponsesByResourceSlug = (
  workflowType,
  resourceType,
  resourceSlug
) => {
  const resource = `/workflow/${workflowType}/${resourceType}/${resourceSlug}/responses`

  return createRequest(resource)
}

const getWorkflowResponseByResponseSlug = (
  workflowType,
  resourceType,
  resourceSlug,
  responseSlug
) => {
  const resource = `/workflow/${workflowType}/${resourceType}/${resourceSlug}/response/${responseSlug}`

  return createRequest(resource)
}

// developer

const getDeveloperAccessToken = userSlug => {
  const resource = `/user/${userSlug}/developer/access-token`

  return createRequest(resource)
}

const generateDeveloperAccessToken = userSlug => {
  const resource = `/user/${userSlug}/developer/access-token`
  const opts = { method: HTTP_METHODS.POST }

  return createRequest(resource, opts)
}

const revokeApiToken = userSlug => {
  const resource = `/user/${userSlug}/developer/access-token/revoke`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getDeveloperAccessTokenHistory = userSlug => {
  const resource = `/user/${userSlug}/developer/access-token/history`

  return createRequest(resource)
}

// leads

const getAllLeads = (qs = '') => {
  const resource = `/leads${qs}`

  return createRequest(resource)
}

// refresh auth token

const refreshAuthToken = async refreshToken => {
  const url = `${process.env.REACT_APP_API_BASE}/auth/token/refresh`
  const opts = {
    method: HTTP_METHODS.POST,
    headers: {
      Accept: 'application/json',
      Authorization: refreshToken,
    },
  }

  const response = await fetch(url, opts)

  if (response.ok) {
    const result = await response.json()

    return camelizeKeys(result)
  } else {
    return Promise.reject({ apiError: { message: 'unauthorized' } })
  }
}

// comms hub

const getAllSupportPhoneNumbers = (qs = '') => {
  const resource = `/communication_hub/support_phones${qs}`

  return createRequest(resource)
}

const createSupportPhoneNumber = body => {
  const resource = '/communication_hub/support_phones'
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }

  return createRequest(resource, opts)
}

const updateSupportPhoneNumber = body => {
  const resource = `/communication_hub/support_phone/${body.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const getSupportPhoneNumberBySlug = slug => {
  const resource = `/communication_hub/support_phone/${slug}`

  return createRequest(resource)
}

const deleteSupportPhoneNumber = slug => {
  const resource = `/communication_hub/support_phone/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllConversations = (qs = '') => {
  const resource = `/communication_hub/conversations${qs}`

  return createRequest(resource)
}

const getConversationBySlug = (slug, qs = '') => {
  const resource = `/communication_hub/conversation/${slug}${qs}`

  return createRequest(resource)
}

const createConversation = (userSlugs, message) => {
  const resource = '/communication_hub/conversations'

  const opts = {
    method: HTTP_METHODS.POST,
    body: { userSlugs, message },
  }

  return createRequest(resource, opts)
}

const closeConversation = conversationSlug => {
  const resource = `/communication_hub/conversation/${conversationSlug}/close`

  const opts = {
    method: HTTP_METHODS.PUT,
    body: {},
  }

  return createRequest(resource, opts)
}

// action reports

const getAllActionReports = (qs = '') => {
  const resource = `/action-reports${qs}`

  return createRequest(resource)
}

// chat

const createBridgedPhoneCall = ({ userPhoneNumber, userSlug }) => {
  const resource = '/chat/voice/outgoing'

  const opts = {
    method: HTTP_METHODS.POST,
    body: { userPhoneNumber, userSlug },
  }

  return createRequest(resource, opts)
}

const getAllBLEDataByCompany = companyName => {
  const resource = `/inventory/ble/${companyName}/data`

  return createRequest(resource)
}

// network authority

const createNetworkAuthority = name => {
  const resource = '/network/authorities'
  const opts = {
    method: HTTP_METHODS.POST,
    body: { name },
  }

  return createRequest(resource, opts)
}

const updateNetworkAuthority = (slug, name) => {
  const resource = `/network/authority/${slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: { name },
  }

  return createRequest(resource, opts)
}

const deleteNetworkAuthority = slug => {
  const resource = `/network/authority/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllNetworkAuthorities = (qs = '') => {
  const resource = `/network/authorities${qs}`

  return createRequest(resource)
}

const getNetworkAuthorityBySlug = slug => {
  const resource = `/network/authority/${slug}`

  return createRequest(resource)
}

// network

const createNetwork = body => {
  const resource = '/networks'
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }

  return createRequest(resource, opts)
}

const updateNetwork = body => {
  const resource = `/network/${body.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const deleteNetwork = slug => {
  const resource = `/network/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllNetworks = (qs = '') => {
  const resource = `/networks${qs}`

  return createRequest(resource)
}

const getNetworkBySlug = slug => {
  const resource = `/network/${slug}`

  return createRequest(resource)
}

const regenerateNetworkAuthorityToken = slug => {
  const resource = `/network/authority/${slug}/token/regenerate`
  const opts = {
    method: HTTP_METHODS.POST,
    body: {},
  }

  return createRequest(resource, opts)
}

const getNetworkAuthorityTokenHistory = slug => {
  const resource = `/network/authority/${slug}/token/history`

  return createRequest(resource)
}

// alert summaries

const getAllAlertSummaries = (qs = '') => {
  const resource = `/config/alert-summaries${qs}`

  return createRequest(resource)
}

const getAlertSummaryBySlug = slug => {
  const resource = `/config/alert-summary/${slug}`

  return createRequest(resource)
}

const createAlertSummary = summary => {
  const resource = '/config/alert-summaries'
  const opts = {
    method: HTTP_METHODS.POST,
    body: {
      ...summary,
      scheduledTime: moment(summary.scheduledTime, 'hh:mm')
        .utc()
        .format('HH:mm:00+00:00'),
    },
  }

  return createRequest(resource, opts)
}

const updateAlertSummary = summary => {
  const resource = `/config/alert-summary/${summary.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: {
      ...summary,
      scheduledTime: moment(summary.scheduledTime, 'hh:mm')
        .utc()
        .format('HH:mm:00+00:00'),
    },
  }

  return createRequest(resource, opts)
}

const deleteAlertSummary = slug => {
  const resource = `/config/alert-summary/${slug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

// location groups

const createLocationGroup = (siteSlug, body) => {
  const resource = `/site/${siteSlug}/location-groups`
  const opts = {
    method: HTTP_METHODS.POST,
    body,
  }

  return createRequest(resource, opts)
}

const updateLocationGroup = (siteSlug, body) => {
  const resource = `/site/${siteSlug}/location-group/${body.slug}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body,
  }

  return createRequest(resource, opts)
}

const deleteLocationGroup = (siteSlug, groupSlug) => {
  const resource = `/site/${siteSlug}/location-group/${groupSlug}`
  const opts = {
    method: HTTP_METHODS.DELETE,
  }

  return createRequest(resource, opts)
}

const getAllLocationGroups = (siteSlug, qs = '') => {
  const resource = `/site/${siteSlug}/location-groups${qs}`

  return createRequest(resource)
}

const getLocationGroup = (siteSlug, groupSlug) => {
  const resource = `/site/${siteSlug}/location-group/${groupSlug}`

  return createRequest(resource)
}

const addThresholdToLocationGroup = (
  siteSlug,
  locationGroupSlug,
  thresholdId,
  thresholdGroupSlug
) => {
  const resource = `/site/${siteSlug}/location-group/${locationGroupSlug}/thresholds`
  const opts = {
    method: HTTP_METHODS.POST,
    body: {
      thresholdId,
      thresholdGroupSlug,
    },
  }

  return createRequest(resource, opts)
}

const getAllThresholdsByLocationGroup = (siteSlug, locationGroupSlug) => {
  const resource = `/site/${siteSlug}/location-group/${locationGroupSlug}/thresholds`

  return createRequest(resource)
}

const deleteThresholdFromLocationGroup = (
  siteSlug,
  locationGroupSlug,
  locationGroupThresholdId
) => {
  const resource = `/site/${siteSlug}/location-group/${locationGroupSlug}/threshold/${locationGroupThresholdId}`
  const opts = { method: HTTP_METHODS.DELETE }

  return createRequest(resource, opts)
}

const getAllLocationGroupThresholdUserMappings = (
  siteSlug,
  locationGroupSlug
) => {
  const resource = `/site/${siteSlug}/location-group/${locationGroupSlug}/threshold-user-mappings`

  return createRequest(resource)
}

const updateLocationGroupThresholdUserMapping = mapping => {
  const resource = `/site/${mapping.siteSlug}/location-group/${mapping.locationGroupSlug}/threshold-user-mapping/${mapping.mappingId}`
  const opts = {
    method: HTTP_METHODS.PUT,
    body: mapping,
  }

  return createRequest(resource, opts)
}

const evacuateBuilding = (building_id, action = true) => {
  const resource = `/site/send-recon-msg/evacuate-building/${building_id}`

  const opts = {
    method: HTTP_METHODS.POST,
    body: { building_id: building_id, action: action },
  }

  return createRequest(resource, opts)
}

const getFlowSchedule = (monitor_id) => {
  const timezone = getTimeZone().replace('/', '-')
  const resource = `/flow-monitor/${monitor_id}/schedule/${timezone}`

  const opts = { method: HTTP_METHODS.GET }

  return createRequest(resource, opts);
}

const pushFlowSchedule = (monitor_id, windows, inactive_threshold, inactive_status) => {
    const timezone = getTimeZone()
    const resource = `/flow-monitor/${monitor_id}/update-schedule`

    const opts = {
        method: HTTP_METHODS.POST,
        body: {
            windows: windows,
            timezone: timezone,
            inactive_status: inactive_status,
            inactive_threshold: inactive_threshold
        },
    }

    return createRequest(resource, opts)
}

const setWaterValveStatus = (flow_id, status) => {
    const resource = `/water-valve/${flow_id}/status`

    const opts = {
        method: HTTP_METHODS.POST,
        body: { 
            status: status
        },
    }

    return createRequest(resource, opts)
}

// public API

const api = {
  HTTP_METHODS,
  authorize,
  requestResetPasswordEmail,
  resetPassword,
  verifyResetPasswordToken,
  requestAccountConfirmationEmail,
  confirmAccount,
  getUnconfirmedUser,
  getAllCompanies,
  getCompanyById,
  createCompany,
  updateCompany,
  deleteCompany,
  getAllSitesByCompany,
  getAllUsersByCompany,
  getAllEscalationPoliciesByCompany,
  getAllUsers,
  getUserBySlug,
  createUser,
  updateUser,
  deleteUser,
  getUserCommunicationLog,
  getUserPermissionsBySlug,
  updateUserPermissions,
  updateUserDefaultSite,
  getAllSites,
  getSiteBySlug,
  createSite,
  updateSite,
  deleteSite,
  getAllUsersBySite,
  getAllAvailableUsersBySite,
  getInventoryHistoryBySite,
  addUserToSite,
  deleteUserFromSite,
  getAllGatewaysBySite,
  addGatewayToSite,
  deleteGatewayFromSite,
  getAllThresholdsBySite,
  addThresholdToSite,
  deleteThresholdFromSite,
  getAllAvailablePodsBySite,
  sendTestNotification,
  getAllGateways,
  createGateway,
  getAllAvailableGateways,
  getGatewayById,
  updateGateway,
  deleteGateway,
  getAllPods,
  getAllAvailablePods,
  createPod,
  getPodById,
  getPodChartDataById,
  updatePod,
  batchUpdatePods,
  deletePod,
  getAllFloors,
  getFloorByFloor,
  createFloor,
  submitSiteFloors,
  updateFloor,
  uploadFloorPlan,
  uploadFloorPlanPdf,
  getFloorPlanSnaps,
  deleteFloor,
  getAllLocationsBySite,
  getAllLocationsByFloor,
  getAllLocationsSnapshotDataByFloor,
  getLocationById,
  getLocationReadingsById,
  getLocationChartDataById,
  getLocationDataByLocationIds,
  createLocation,
  updateLocation,
  deleteLocation,
  getAllThresholdsByLocation,
  addThresholdToLocation,
  deleteThresholdFromLocation,
  getAllThresholds,
  createThreshold,
  getThresholdById,
  updateThreshold,
  deleteThreshold,
  getThresholdAssignmentsById,
  getAllAlerts,
  getAlertBySlug,
  hideAlert,
  unhideAlert,
  closeAlert,
  acknowledgeAlert,
  resolveAlert,
  getAccountProfile,
  updateAccountProfile,
  updateAccountPassword,
  getDashboardCounts,
  getDashboardAlerts,
  getAllBuildings,
  getBuildingById,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  getAllDeployments,
  getDeploymentCounts,
  getAllDeploymentsBySite,
  createDeployment,
  updateDeployment,
  deleteDeployment,
  getDeployment,
  getPodMetadata,
  getShipment,
  updateShipment,
  createReport,
  getAllReports,
  getPublicReportByReportSlug,
  requestVerificationToken,
  confirmVerificationToken,
  getAllPodFirmwareVersions,
  updatePodFirmware,
  getAllOTALogs,
  getOTALogBySlug,
  getInventoryCounts,
  getInventoryProjections,
  getAllEscalationPolicies,
  getEscalationPolicyBySlug,
  createEscalationPolicy,
  updateEscalationPolicy,
  deleteEscalationPolicy,
  generatePillarIDs,
  createAssetLocation,
  getAllAssetLocations,
  getAssetLocation,
  deleteAssetLocation,
  updateAssetLocation,
  getAllTags,
  createTag,
  updateTag,
  deleteTag,
  getAllComments,
  createComment,
  updateComment,
  deleteComment,
  getAllGatewayAlerts,
  generateGrafanaJSON,
  getAllSiteThresholdUserMappings,
  getAllSiteAggregateThresholdUserMappings,
  updateSiteThresholdUserMapping,
  getAllLocationThresholdUserMappings,
  updateLocationThresholdUserMapping,
  addEscalationPolicytoSiteThreshold,
  addEscalationPolicytoLocationThreshold,
  getPodAssignmentHistory,
  getPodEditHistory,
  getFlowMonitorAssignmentHistory,
  getValveStatusHistory,
  getFlowMonitorEditHistory,
  getPodCalibrationHistory,
  getGatewayUptimeData,
  getGatewayConnectedPods,
  getLocationUptimeData,
  getAllFloorSummaries,
  getFloorSummary,
  getAlertAuditLog,
  getAllShipments,
  getGatewayAlertBySlug,
  getLocationAlertHistory,
  restartGatewayById,
  getGatewayRestartHistoryByPillarId,
  getAllSIMCards,
  createSIMCard,
  getAllAvailableSIMCards,
  getSIMCard,
  updateSIMCard,
  deleteSIMCard,
  getAllParts,
  createPart,
  getAllAvailableParts,
  getPart,
  updatePart,
  deletePart,
  getAllPurchases,
  createPurchase,
  getAllAvailablePurchases,
  getPurchase,
  updatePurchase,
  deletePurchase,
  getAllFlowMonitors,
  getFlowMonitor,
  createFlowMonitor,
  updateFlowMonitor,
  deleteFlowMonitor,
  getAllWaterMeters,
  getWaterMeter,
  createWaterMeter,
  updateWaterMeter,
  deleteWaterMeter,
  getAllWaterValves,
  getWaterValve,
  createWaterValve,
  updateWaterValve,
  deleteWaterValve,
  getAllAvailableFlowMonitorsBySite,
  getAllAvailableWaterValves,
  getAllAvailableWaterMeters,
  getAllGatewaySupplies,
  createGatewaySupply,
  getAllAvailableGatewaySupplies,
  getGatewaySupply,
  updateGatewaySupply,
  deleteGatewaySupply,
  getPodAssignmentHistoryByLocation,
  getPodAssignmentHistoryBySite,
  getSiteAssets,
  updateGatewayLocation,
  markPodAsLost,
  closeGatewayAlert,
  getAllThresholdsByBuilding,
  addThresholdToBuilding,
  deleteThresholdFromBuilding,
  getAllThresholdsByFloor,
  addThresholdToFloor,
  deleteThresholdFromFloor,
  getAllBuildingThresholdUserMappings,
  updateBuildingThresholdUserMapping,
  getAllFloorThresholdUserMappings,
  updateFloorThresholdUserMapping,
  getGatewayAssignmentHistory,
  getThresholdEditHistoryById,
  getAllAggregateThresholdsBySite,
  getTagById,
  uploadLogo,
  regeneratePackingList,
  updateDeploymentEstimates,
  getDeploymentEditHistory,
  resendTwoFactorAuthToken,
  verifyTwoFactorAuthToken,
  uploadPodsCSV,
  uploadCalibrationCSV,
  uploadCalibrationZipfile,
  getSiteActivityLog,
  getSiteContractors,
  getAllImuNanoIots,
  getImuData,
  getAllFlowMonitorsData,
  getFlowMonitorData,
  addSiteContractor,
  deleteSiteContractor,
  getSiteRiMetrics,
  createSiteActionReport,
  updateSiteActionReport,
  deleteSiteActionReport,
  getAllActionReportCauses,
  getActionReportCauseBySlug,
  createActionReportCause,
  updateActionReportCause,
  deleteActionReportCause,
  uploadUsersCSV,
  getAllFloorsByBuildingId,
  getAllFloorSummariesByBuildingId,
  updateFloorSortOrderBySiteSlug,
  getPodAssets,
  getAllWorkflows,
  createWorkflow,
  getWorkflowByType,
  getAllWorkflowResponsesByResourceSlug,
  getWorkflowResponseByResponseSlug,
  getDeveloperAccessToken,
  generateDeveloperAccessToken,
  revokeApiToken,
  getDeveloperAccessTokenHistory,
  getAllLeads,
  refreshAuthToken,
  getAllSupportPhoneNumbers,
  getSupportPhoneNumberBySlug,
  createSupportPhoneNumber,
  updateSupportPhoneNumber,
  deleteSupportPhoneNumber,
  getAllConversations,
  getConversationBySlug,
  createConversation,
  closeConversation,
  getAllActionReports,
  createBridgedPhoneCall,
  generateAlertExport,
  batchUpdateAlerts,
  getAllAvailbleUserMentions,
  getAllBLEDataByCompany,
  createNetworkAuthority,
  updateNetworkAuthority,
  deleteNetworkAuthority,
  getAllNetworkAuthorities,
  getNetworkAuthorityBySlug,
  createNetwork,
  updateNetwork,
  deleteNetwork,
  getAllNetworks,
  getNetworkBySlug,
  regenerateNetworkAuthorityToken,
  getNetworkAuthorityTokenHistory,
  getAllReportConfigs,
  createReportConfig,
  updateReportConfig,
  deleteReportConfig,
  getAllThresholdGroups,
  createThresholdGroup,
  updateThresholdGroup,
  deleteThresholdGroup,
  getThresholdGroupBySlug,
  createInventoryProposal,
  getAllInventoryProposals,
  getInventoryProposalBySlug,
  updateInventoryProposal,
  deleteInventoryProposal,
  generatePillarIDsFromInventoryProposal,
  getAllAlertSummaries,
  getAlertSummaryBySlug,
  createAlertSummary,
  updateAlertSummary,
  deleteAlertSummary,
  createLocationGroup,
  updateLocationGroup,
  deleteLocationGroup,
  getAllLocationGroups,
  getLocationGroup,
  addThresholdToLocationGroup,
  getAllThresholdsByLocationGroup,
  deleteThresholdFromLocationGroup,
  getAllLocationGroupThresholdUserMappings,
  updateLocationGroupThresholdUserMapping,
  getAllSiteInvoices,
  createSiteInvoice,
  updateSiteInvoice,
  deleteSiteInvoice,
  uploadSiteDocument,
  getAllSiteDocuments,
  getMultiplePodsData,
  evacuateBuilding,
  getFlowSchedule,
  pushFlowSchedule,
  setWaterValveStatus,
}

export { api as default, createRequest }
