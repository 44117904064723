import React, { Component } from 'react'
import PropTypes from 'prop-types'
// TODO why using extract?
import { extract } from 'query-string'
import sortBy from 'sort-by'
import AllFloorsBlock from './AllFloorsBlock'
import SortableBuilding from './SortableBuilding'
import { RadioButton, RadioGroup } from './common/Ant'
import { H3, H4 } from './common/Headers'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import PODIcon from './common/PODIcon'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { getPathSnippets } from '../utils/helpers'
import { BUILDING_VIEW_TYPES, USER_PERMISSIONS, ROLES } from '../constants'

class Buildings extends Component {
  static propTypes = {
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    floors: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteSlug: PropTypes.string.isRequired,
    handleModalOpen: PropTypes.func,
    updateSortOrder: PropTypes.func,
    deleteBuilding: PropTypes.func,
    deleteFloor: PropTypes.func,
    isFloorLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    updateQueryParams: PropTypes.func,
    hasBuildings: PropTypes.bool.isRequired,
  }

  state = {
    viewType: BUILDING_VIEW_TYPES.LOCATIONS,
  }

  componentDidMount() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    const searchParams = extract(global.location.search) === 'summaries'
    if (searchParams) {
      this.setState({ viewType: 'summaries' })
    } else if (lastSnippet === 'buildings') {
      this.setState({ viewType: 'locations' })
    }
  }

  componentWillReceiveProps(nextProps) {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    const searchParams = extract(global.location.search) === 'summaries'
    if (searchParams) {
      this.setState({ viewType: 'summaries' })
    } else if (lastSnippet === 'buildings') {
      this.setState({ viewType: 'locations' })
    }
  }

  handleViewTypeChange = ({ target: { value } }) => {
    this.setState({ viewType: value })

    const { updateQueryParams, match } = this.props

    updateQueryParams({
      pathname: `${match.url}/buildings`,
      search: value === 'summaries' ? value : '',
    })
  }

  render() {
    const {
      buildings,
      floors,
      siteSlug,
      handleModalOpen,
      updateSortOrder,
      deleteBuilding,
      deleteFloor,
      isFloorLoading,
      hasBuildings,
    } = this.props
    const { viewType } = this.state

    let unassignedFloors = []
    if (hasBuildings) {
      unassignedFloors = floors.filter(({ buildingId }) => !buildingId)

      console.log("buildings")
      buildings.forEach(building => {
        const floorData = []
        console.log(building)

        floors.forEach(floor => {
          if (building.id === floor.buildingId) {
            floorData.push(floor)
          }
        })

        building.floors = floorData.sort(sortBy('sortOrder'))
      })
    }

    return (
      <div className="Buildings mb5">
        {hasBuildings ? (
          <>
            <div className="flex-ns items-center-ns justify-between-ns">
              <div className="flex-ns">
                <H3 className="mr3">Buildings</H3>
                {hasPermission(USER_PERMISSIONS.ADD_BUILDING) && (
                  <Anchor
                    to={`/sites/${siteSlug}/buildings/create`}
                    className="mb3 mr3"
                    button
                  >
                    Create New Building
                  </Anchor>
                )}
              </div>
              {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                <RadioGroup
                  onChange={this.handleViewTypeChange}
                  value={viewType}
                  className="mb3"
                  size="small"
                >
                  <RadioButton value={BUILDING_VIEW_TYPES.LOCATIONS}>
                    Locations
                  </RadioButton>
                  <RadioButton value={BUILDING_VIEW_TYPES.SUMMARIES}>
                    Summaries
                  </RadioButton>
                </RadioGroup>
              )}
            </div>
            {buildings.map(x => (
              <SortableBuilding
                {...x}
                key={x.id}
                siteSlug={siteSlug}
                deleteBuilding={deleteBuilding}
                deleteFloor={deleteFloor}
                handleModalOpen={handleModalOpen}
                isFloorLoading={isFloorLoading}
                viewType={viewType}
                updateSortOrder={updateSortOrder}
              />
            ))}
            {unassignedFloors.length > 0 && (
              <div className="bg-near-white pa3 br2 ba b--light-gray mb3">
                <H4>Unassigned</H4>
                <AllFloorsBlock
                  floors={unassignedFloors}
                  siteSlug={siteSlug}
                  deleteFloor={deleteFloor}
                  handleModalOpen={handleModalOpen}
                  isFloorLoading={isFloorLoading}
                  viewType={viewType}
                />
              </div>
            )}
          </>
        ) : (
          <div className="mb3">
            <div className="flex-ns items-center-ns justify-between-ns">
              <div className="flex-ns">
                <H3 className="mr3">Floors</H3>
                {hasPermission(USER_PERMISSIONS.ADD_BUILDING) && (
                  <>
                    <Anchor
                      to={`/sites/${siteSlug}/buildings/create`}
                      className="mb3 mr3"
                      button
                    >
                      Create New Building
                    </Anchor>
                    <Anchor
                      to={`/sites/${siteSlug}/floors/create`}
                      className="mb3 mr3"
                      button
                    >
                      Create New Floor
                    </Anchor>
                  </>
                )}
              </div>
              <RadioGroup
                onChange={this.handleViewTypeChange}
                value={viewType}
                className="mb3"
                size="small"
              >
                <RadioButton value={BUILDING_VIEW_TYPES.LOCATIONS}>
                  Locations
                </RadioButton>
                <RadioButton value={BUILDING_VIEW_TYPES.SUMMARIES}>
                  Summaries
                </RadioButton>
              </RadioGroup>
            </div>
            <AllFloorsBlock
              floors={floors}
              siteSlug={siteSlug}
              deleteFloor={deleteFloor}
              handleModalOpen={handleModalOpen}
              isFloorLoading={isFloorLoading}
              viewType={viewType}
            />
          </div>
        )}
        <Divider />
        <div className="bg-dark-gray br2 pa3 white mb3 o-90">
          <H3 className="white">Legend</H3>
          <div className="flex items-center justify-center mb4">
            <PODIcon color="green" />
            <div className="ml2">Smart Pod</div>
            <Divider vertical />
            <div
              className={`br1 bg-green flex items-center justify-center mr2`}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            >
              <span
                className="b white"
                style={{
                  margin: '-1px -1px 0 0',
                }}
              >
                L
              </span>
            </div>
            <div>Leak Puck</div>
            <Divider vertical />
            <div
              className={`br1 bg-green flex items-center justify-center mr2`}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            >
              <span
                className="b white"
                style={{
                  margin: '-1px -1px 0 0',
                }}
              >
                E
              </span>
            </div>
            <div>Evac Pod</div>
            <Divider vertical />
            <div
              className={`br1 mr2 bg-green rotate-45 flex items-center justify-center `}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            >
              <span
                className="b white rotate-315"
                style={{
                  margin: '-1px -1px 0 0',
                }}
              >
                W
              </span>
            </div>
            <div>Water Monitor</div>
            <Divider vertical />
            <div
              className={`br-100 bg-green flex items-center justify-center mr2`}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            >
              <span
                className="b white"
                style={{
                  margin: '-1px -1px 0 0',
                }}
              >
                G
              </span>
            </div>
            <div>Network Gateway</div>
            <Divider vertical />
            <div
              className={`br1 bg-silver flex items-center justify-center mr2`}
              style={{
                width: '1.25rem',
                height: '1.25rem',
              }}
            >
              <span
                className="b white"
                style={{
                  margin: '-1px -1px 0 0',
                }}
              >
                U
              </span>
            </div>
            <div>Unassigned</div>
          </div>
          <div className="flex items-center justify-center mb3">
            <div className={`br2 bg-green ph1 mr2`}>
              <div>Online</div>
            </div>
            <Divider vertical />
            <div className={`br2 bg-orange ph1 mr2`}>
              <div>Offline</div>
            </div>
            <Divider vertical />
            <div className={`br2 bg-gold ph1 mr2 dark-gray`}>
              <div>Informational Alert</div>
            </div>
            <Divider vertical />
            <div className={`br2 bg-dark-red ph1`}>
              <div>Actionable Alert</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Buildings
